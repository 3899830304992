import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/pt_PT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/instantglow/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/instantglow/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/instantglow/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/instantglow/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/instantglow/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/instantglow/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            {/* <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2019-banner-es-instant-glow.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2019-es-instant-glow-mobile.jpg"
                  />
                </div>
              </div>
            </section> */}
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN INSTANT GLOW<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>
                        Ilumina a pele instantaneamente. Com pérolas refletoras
                      </h3>
                      <p>
                        Agora, a sua pele pode ter aquele brilho de verão
                        deslumbrante instantaneamente e durante todo o verão -
                        sem comprometer a proteção. PIZ BUIN INSTANT GLOW
                        <sup>®</sup> contém delicadas pérolas refletoras que
                        iluminam a sua pele com um brilho dourado deslumbrante e
                        subtil enquanto o sistema de filtros de proteção solar
                        avançado UVA/UVB de PIZ BUIN<sup>®</sup> dá à sua pele
                        uma proteção solar imediata e eficaz. Não é um produto
                        autobronzeador!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="spray-solar-iluminador" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-instant-glow-skin-illuminating-sun-spray-30spf-150ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SPRAY SOLAR ILUMINADOR</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          A fórmula não gordurosa, não pegajosa e de fácil
                          absorção hidrata a sua pele. E, graças ao formato
                          inovador da embalagem, pode aplicar o spray virado
                          para baixo, para uma aplicação mais fácil e
                          conveniente, a qualquer momento. Não contém
                          autobronzeador.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>DISPONÍVEL NOS FATORES DE PROTEÇÃO SOLAR</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>RECOMENDAÇÃO DE USO</h4>
                        <p>
                          Aplicar em doses generosas e uniformemente antes da
                          exposição ao sol. A aplicação de uma dose reduzida
                          diminui significativamente o nível de proteção.
                          Reaplique frequentemente, especialmente depois de
                          transpirar, nadar ou secar-se com uma toalha. Evite a
                          exposição solar ao meio-dia e ao longo de muitas
                          horas, mesmo quando usa proteção solar. Manter bebés e
                          crianças afastados da exposição direta ao sol. Para
                          uso facial coloque nas mãos e depois aplique.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                BETACAROTENO
                                <div className="textBlock bottom">
                                  <p>
                                    O betacaroteno é um pigmento cor de laranja
                                    abundante em plantas e frutas combinadas.
                                    Ajuda a dar à pele uma cor natural
                                    instantânea e uma aparência saudável. É
                                    facilmente lavado com sabão. Não é um
                                    autobronzeador!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fa5146555d82b0de6956d3ffaf04bf1c_f91.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CARAMELO
                                <div className="textBlock bottom">
                                  <p>
                                    O caramelo é um corante de cor castanha que
                                    dá instantaneamente à pele uma cor natural e
                                    uma aparência saudável. É facilmente lavado
                                    com sabão. Não é um autobronzeador!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4bca608325f7d1f697e7783639540cfe_f92.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                PÉROLAS RELFETORAS
                                <div className="textBlock bottom">
                                  <p>
                                    As nossas pérolas refletoras delicadas têm
                                    uma tecnologia de revestimento avançada
                                    refletindo uma cor dourada. Graças à sua
                                    tecnologia de pigmento em multicamada a luz
                                    é refletida em cada camada de pigmento
                                    resultando numa reflexão mais intensa e com
                                    uma cor mais pura. O resultado final é uma
                                    cor dourada subtil e impressionante,
                                    proporcionando à sua pele um brilho imediato
                                    e deslumbrante e realçando o seu tom e
                                    beleza natural. Não é um autobronzeador!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/197557fee29adfef39ee77a61900c7bb_f90.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="lo-o-solar-iluminadora" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/1668375df47225efbcfeb9eeeebea57c_f404.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LOÇãO SOLAR ILUMINADORA</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          Loção Solar Iluminadora que apresenta uma fórmula que
                          ilumina subtilmente e instantaneamente a pele.Com
                          delicadas pérolas refletoras, esta loção ilumina
                          instantaneamente a sua pele com um bonito e subtil
                          brilho dourado que realça o seu tom de pele natural,
                          ao mesmo tempo que proporciona uma proteção solar
                          UVA/UVB imediata e eficaz. A fórmula leve, não
                          gordurosa e não pegajosa é rapidamente absorvida,
                          hidratando a sua pele. Nota: não contém
                          autobronzeador.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>DISPONÍVEL NOS FATORES DE PROTEÇÃO SOLAR</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                        <span className="factor factor50" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>RECOMENDAÇÃO DE USO</h4>
                        <p>
                          Aplicar em doses generosas antes da exposiçao ao sol.
                          A aplicaçao de uma dose reduzida diminui
                          significativamente o <br />
                          nível de proteção. Reaplique frequentemente,
                          especialmente depois de transpirar, nadar ou secar-se
                          com uma toalha. Evite a exposição solar ao meio-dia e
                          ao longo de muitas horas, mesmo quando usa proteção
                          solar. Manter bebés e crianças afastados da exposição
                          directa ao sol.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                BETACAROTENO
                                <div className="textBlock bottom">
                                  <p>
                                    O betacaroteno é um pigmento cor de laranja
                                    abundante em plantas e frutas combinadas.
                                    Ajuda a dar à pele uma cor natural
                                    instantânea e uma aparência saudável. É
                                    facilmente lavado com sabão. Não é um
                                    autobronzeador!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/fa5146555d82b0de6956d3ffaf04bf1c_f91.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                CARAMELO
                                <div className="textBlock bottom">
                                  <p>
                                    O caramelo é um corante de cor castanha que
                                    dá instantaneamente à pele uma cor natural e
                                    uma aparência saudável. É facilmente lavado
                                    com sabão. Não é um autobronzeador!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/4bca608325f7d1f697e7783639540cfe_f92.jpg" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                PÉROLAS RELFETORAS
                                <div className="textBlock bottom">
                                  <p>
                                    As nossas pérolas refletoras delicadas têm
                                    uma tecnologia de revestimento avançada
                                    refletindo uma cor dourada. Graças à sua
                                    tecnologia de pigmento em multicamada a luz
                                    é refletida em cada camada de pigmento
                                    resultando numa reflexão mais intensa e com
                                    uma cor mais pura. O resultado final é uma
                                    cor dourada subtil e impressionante,
                                    proporcionando à sua pele um brilho imediato
                                    e deslumbrante e realçando o seu tom e
                                    beleza natural. Não é um autobronzeador!
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/197557fee29adfef39ee77a61900c7bb_f90.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/pt/nossos-produtos/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/pt/nossos-produtos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/pt/nossos-produtos/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/pt/nossos-produtos/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/pt/nossos-produtos/instantglow/"
                      target="_self"
                    >
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/pt/nossos-produtos/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/pt/nossos-produtos/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a
                      href="/pt/nossos-produtos/todos_os_Produtos/"
                      target="_self"
                    >
                      Todos os Produtos
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Saiba Mais');\n} else {\nbox.slideDown();\n$(this).text('Fechar');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
